import axios from "axios";

interface CitnowSalesforceApi {
    generateUsageRecordsSummariesCsvDownloadLink(from: string, to: string): string;
    uploadUsageRecordsSummaries(from: string, to: string): Promise<void>;
}

export const citnowSalesforceApi: CitnowSalesforceApi = {
    generateUsageRecordsSummariesCsvDownloadLink(from, to) {
        return (
            "/api/citnow-salesforce/usage_records_summaries.csv?from=" +
            encodeURIComponent(from) +
            "&to=" +
            encodeURIComponent(to)
        );
    },

    async uploadUsageRecordsSummaries(from, to) {
        await axios.post("/api/citnow-salesforce/_upload-usage-records-summaries", undefined, {
            params: {
                from,
                to,
            },
        });
    },
};
