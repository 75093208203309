
import CitnowSalesforceUsageRecordsSummariesConsumerDialog from "./CitnowSalesforceUsageRecordsSummariesConsumerDialog.vue";
import { citnowSalesforceApi } from "@/api/citnowSalesforce";
import { configStore } from "@/store/config";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            usageRecordsSummariesDownloadDialogVisible: false,
            usageRecordsSummariesUploadDialogVisible: false,
            working: false,
        };
    },

    computed: {
        locale(): string {
            return configStore.configuration.defaultLocale;
        },

        timeZone(): string {
            return configStore.configuration.organisationTimeZone;
        },
    },

    methods: {
        downloadRecordsSummariesAsCsv(from: string, to: string) {
            window.open(citnowSalesforceApi.generateUsageRecordsSummariesCsvDownloadLink(from, to));
        },

        async uploadUsageRecordsSummaries(from: string, to: string) {
            await citnowSalesforceApi.uploadUsageRecordsSummaries(from, to);
        },
    },

    components: {
        CitnowSalesforceUsageRecordsSummariesConsumerDialog,
    },
});
